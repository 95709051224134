import { getRequest, postRequest, patchRequest, deleteRequest, severityCode } from "./httpClient";

const END_POINT = "iam";

const iamType = {
  User: { single: "user", multi: "users" },
  UserGroup: { single: "usergroup", multi: "usergroups" },
  Resource: { single: "resource", multi: "resources" },
  Project: { single: "project", multi: "projects" },
  Role: { single: "role", multi: "roles" },
  Permission: { single: "permission", multi: "permissions" },
  PermissionCategory: { single: "permissioncategory", multi: "permissioncategories" },
  Category: { single: "category", multi: "categories" },
};

const getUsers = async (currentPage, pageSize, sortBy, sortDesc, filterData, orConditionsPerFilter, showOnlyActive) => {
  console.log("iam, getUsers filterData, showOnlyActive: ", filterData, showOnlyActive);
  return postRequest(
    `${END_POINT}/users/overview`,
    { filterData: filterData },
    {
      pageNumber: currentPage,
      pageSize: pageSize,
      sortBy: sortBy,
      sortDesc: sortDesc,
      orConditionsPerFilter: orConditionsPerFilter,
      showOnlyActive: showOnlyActive,
    }
  );
};

const getUsersFilterOptions = async (fieldName, text, filterData, showOnlyActive) => {
  return postRequest(`${END_POINT}/users/autocomplete/${fieldName}`, { filterData: filterData }, { text: text, showOnlyActive: showOnlyActive });
};

const getUserGroups = async (currentPage, pageSize, sortBy, sortDesc, filterData) => {
  //console.log("iam, getUserGroups filterData: ", filterData);
  return postRequest(
    `${END_POINT}/usergroups/overview`,
    { filterData: filterData },
    {
      pageNumber: currentPage,
      pageSize: pageSize,
      sortBy: sortBy,
      sortDesc: sortDesc,
    }
  );
};

const getUserGroupsFilterOptions = async (fieldName, text) => {
  return getRequest(`${END_POINT}/usergroups/autocomplete/${fieldName}`, { text: text });
};

const getResources = async (currentPage, pageSize, sortBy, sortDesc, viaAccess, filterData) => {
  return postRequest(
    `${END_POINT}/resources/overview`,
    { filterData: filterData },
    {
      pageNumber: currentPage,
      pageSize: pageSize,
      sortBy: sortBy,
      sortDesc: sortDesc,
      viaAccessDomain: viaAccess ? viaAccess.domain.single : undefined,
      viaAccessId: viaAccess ? viaAccess.id : undefined,
    }
  );
};

const getResourcesFilterOptions = async (fieldName, text) => {
  return { fieldName, text };
};

const getProjects = async (currentPage, pageSize, sortBy, sortDesc, viaAccess, filterData) => {
  return postRequest(
    `${END_POINT}/projects/overview`,
    { filterData: filterData },
    {
      pageNumber: currentPage,
      pageSize: pageSize,
      sortBy: sortBy,
      sortDesc: sortDesc,
      viaAccessDomain: viaAccess ? viaAccess.domain.single : undefined,
      viaAccessId: viaAccess ? viaAccess.id : undefined,
    }
  );
};

const getProjectsFilterOptions = async (fieldName, text) => {
  return { fieldName, text };
};

const getProjectLinks = async (projectId, currentPage, pageSize, sortBy, sortDesc, filterData) => {
  return postRequest(`${END_POINT}/project/${projectId}/links/overview`, { filterData: filterData }, { pageNumber: currentPage, pageSize: pageSize, sortBy: sortBy, sortDesc: sortDesc });
};

const getProjectLinksFilterOptions = async (projectId, fieldName, text) => {
  return { fieldName, text };
};

const getRoles = async (currentPage, pageSize, sortBy, sortDesc, viaAccess, filterData) => {
  return postRequest(
    `${END_POINT}/roles/overview`,
    { filterData: filterData },
    {
      pageNumber: currentPage,
      pageSize: pageSize,
      sortBy: sortBy,
      sortDesc: sortDesc,
      viaAccessDomain: viaAccess ? viaAccess.domain.single : undefined,
      viaAccessId: viaAccess ? viaAccess.id : undefined,
    }
  );
};

const getRolesFilterOptions = async (fieldName, text) => {
  return { fieldName, text };
};

const getPermissions = async (currentPage, pageSize, sortBy, sortDesc, viaAccess, filterData) => {
  return postRequest(
    `${END_POINT}/permissions/overview`,
    { filterData: filterData },
    {
      pageNumber: currentPage,
      pageSize: pageSize,
      sortBy: sortBy,
      sortDesc: sortDesc,
      viaAccessDomain: viaAccess ? viaAccess.domain.single : undefined,
      viaAccessId: viaAccess ? viaAccess.id : undefined,
    }
  );
};

const getPermissionsFilterOptions = async (fieldName, text) => {
  return { fieldName, text };
};

const getPermissionCategories = async (currentPage, pageSize, sortBy, sortDesc, filterData) => {
  return postRequest(`${END_POINT}/permissionCategories/overview`, { filterData: filterData }, { pageNumber: currentPage, pageSize: pageSize, sortBy: sortBy, sortDesc: sortDesc });
};

const getPermissionCategoriesFilterOptions = async (fieldName, text) => {
  return { fieldName, text };
};

const getUser = async (userId) => {
  return getRequest(`${END_POINT}/user/${userId}`);
};

const getUserName = async (userId) => {
  return getRequest(`${END_POINT}/user/${userId}/name`);
};

const getUserLogins = async (userId) => {
  return getRequest(`${END_POINT}/userlogins/${userId}`);
};

const getUserLoginLogs = async (currentPage, pageSize, sortBy, sortDesc, filterData) => {
  //console.log("iam, getUserLoginLogs filterData: ", filterData);
  return postRequest(
    `${END_POINT}/userloginlogs/overview`,
    { filterData: filterData },
    {
      pageNumber: currentPage,
      pageSize: pageSize,
      sortBy: sortBy,
      sortDesc: sortDesc,
    }
  );
};

const getUserLoginLogsFilterOptions = async (fieldName, text, filterData) => {
  return postRequest(`${END_POINT}/userloginlogs/autocomplete/${fieldName}`, { filterData: filterData }, { text: text });
};

const getUserLoginLogsSummary = async (filterData) => {
  //console.log("iam, getUserLoginLogsSummary filterData: ", filterData);
  return postRequest(`${END_POINT}/userloginlogs/summary`, { filterData: filterData });
};

const addUser = async (data) => {
  //console.log("iam, addUser: ", data);
  return postRequest(`${END_POINT}/user`, { ...data });
};

const patchUser = async (userId, data) => {
  //console.log("iam, patchUser: ", userId, data);
  return patchRequest(`${END_POINT}/user/${userId}`, { ...data });
};

const getUserGroup = async (groupId) => {
  return getRequest(`${END_POINT}/usergroup/${groupId}`);
};

const getUserGroupName = async (groupId) => {
  return getRequest(`${END_POINT}/usergroup/${groupId}/name`);
};

const addUserGroup = async (data) => {
  //console.log("iam, addUserGroup: ", data);
  return postRequest(`${END_POINT}/usergroup`, { ...data });
};

const patchUserGroup = async (groupId, data) => {
  //console.log("iam, patchUserGroup: ", groupId, data);
  return patchRequest(`${END_POINT}/usergroup/${groupId}`, { ...data });
};

const getResource = async (resourceId) => {
  return getRequest(`${END_POINT}/resource/${resourceId}`);
};

const getResourceName = async (resourceId) => {
  return getRequest(`${END_POINT}/resource/${resourceId}/name`);
};

const addResource = async (data) => {
  //console.log("iam, addResource: ", data);
  return postRequest(`${END_POINT}/resource`, { ...data });
};

const patchResource = async (resourceId, data) => {
  //console.log("iam, patchResource: ", resourceId, data);
  return patchRequest(`${END_POINT}/resource/${resourceId}`, { ...data });
};

const getProject = async (projectId) => {
  return getRequest(`${END_POINT}/project/${projectId}`);
};

const getProjectName = async (projectId) => {
  return getRequest(`${END_POINT}/project/${projectId}/name`);
};

const addProject = async (data) => {
  //console.log("iam, addProject: ", data);
  return postRequest(`${END_POINT}/project`, { ...data });
};

const patchProject = async (projectId, data) => {
  //console.log("iam, patchProject: ", projectId, data);
  return patchRequest(`${END_POINT}/project/${projectId}`, { ...data });
};

const getProjectLink = async (projectId, projectLinkId) => {
  return getRequest(`${END_POINT}/project/${projectId}/link/${projectLinkId}`);
};

const getProjectLinkName = async (projectId, projectLinkId) => {
  return getRequest(`${END_POINT}/project/${projectId}/link/${projectLinkId}/name`);
};

const addProjectLink = async (projectId, data) => {
  //console.log("iam, addProjectLink: ", projectId, data);
  return postRequest(`${END_POINT}/project/${projectId}/link`, { ...data });
};

const patchProjectLink = async (projectId, projectLinkId, data) => {
  //console.log("iam, patchProjectLink: ", projectLinkId, data);
  return patchRequest(`${END_POINT}/project/${projectId}/link/${projectLinkId}`, { ...data });
};

const getProjectLinkSources = async () => {
  const response = getRequest(`${END_POINT}/projectlinksources`);
  //console.log("iam, getProjectLinkSources: ", response);
  return response;
};

const getRole = async (roleId) => {
  return getRequest(`${END_POINT}/role/${roleId}`);
};

const getRoleName = async (roleId) => {
  return getRequest(`${END_POINT}/role/${roleId}/name`);
};

const addRole = async (data) => {
  //console.log("iam, addRole: ", data);
  return postRequest(`${END_POINT}/role`, { ...data });
};

const patchRole = async (roleId, data) => {
  //console.log("iam, patchRole: ", roleId, data);
  return patchRequest(`${END_POINT}/role/${roleId}`, { ...data });
};

const getPermission = async (permissionKey) => {
  return getRequest(`${END_POINT}/permission/${permissionKey}`);
};

const getPermissionName = async (permissionKey) => {
  addRelationship;
  return getRequest(`${END_POINT}/permission/${permissionKey}/name`);
};

const addPermission = async (data) => {
  //console.log("iam, addPermission: ", data);
  return postRequest(`${END_POINT}/permission`, { ...data });
};

const patchPermission = async (permissionKey, data) => {
  //console.log("iam, patchPermission: ", permissionKey, data);
  return patchRequest(`${END_POINT}/permission/${permissionKey}`, { ...data });
};

const getPermissionCategory = async (permissionCategoryId) => {
  return getRequest(`${END_POINT}/permissionCategory/${permissionCategoryId}`);
};

const getPermissionCategoryName = async (permissionCategoryId) => {
  return getRequest(`${END_POINT}/permissionCategory/${permissionCategoryId}/name`);
};

const addPermissionCategory = async (data) => {
  //console.log("iam, addPermissionCategory: ", data);
  return postRequest(`${END_POINT}/permissionCategory`, { ...data });
};

const patchPermissionCategory = async (permissionCategoryId, data) => {
  //console.log("iam, patchPermissionCategory: ", permissionCategoryId, data);
  return patchRequest(`${END_POINT}/permissionCategory/${permissionCategoryId}`, { ...data });
};

const getAccessOverview = async (currentPage, pageSize, sortBy, sortDesc, sourceView, purposeView, filterData) => {
  return postRequest(
    `${END_POINT}/access/overview`,
    { filterData: filterData },
    {
      pageNumber: currentPage,
      pageSize: pageSize,
      sortBy: sortBy,
      sortDesc: sortDesc,
      domain: sourceView.domain.single,
      domainId: sourceView.id,
      purpose: purposeView.single,
    }
  );
};

const addRelationship = async (targetType, targetId, accesses) => {
  //console.log("iam, addRelationship: ", targetType.single, targetId, accesses);
  return postRequest(`${END_POINT}/${targetType.single}/${targetId}/access`, { ...accesses });
};
const removeRelationship = async (referenceId) => {
  //console.log("iam, removeRelationship: ", referenceId);
  return deleteRequest(`${END_POINT}/access/${referenceId}`);
};

const removeUserGroupFromUser = async (userId, userGroupId) => {
  //console.log("iam, removeUserGroupFromUser: ", userId, userGroupId);
  return deleteRequest(`${END_POINT}/${iamType.User.single}/${userId}/${iamType.UserGroup.single}/${userGroupId}`);
};

const removePermissionFromRole = async (roleId, permissionKey) => {
  //console.log("iam, removePermissionFromRole: ", roleId, permissionKey);
  return deleteRequest(`${END_POINT}/${iamType.Role.single}/${roleId}/${iamType.Permission.single}/${permissionKey}`);
};

const removePermissionFromCategory = async (categoryId, permissionKey) => {
  //console.log("iam, removePermissionFromCategory: ", categoryId, permissionKey);
  return deleteRequest(`${END_POINT}/${iamType.PermissionCategory.single}/${categoryId}/${iamType.Permission.single}/${permissionKey}`);
};

const removeCategoryFromResource = async (resourceId, categoryId) => {
  //console.log("iam, removeCategoryFromResource: ", resourceId, categoryId);
  return deleteRequest(`${END_POINT}/${iamType.Resource.single}/${resourceId}/${iamType.PermissionCategory.single}/${categoryId}`);
};

const identityServerUsers = async (searchText, count) => {
  return getRequest(`${END_POINT}/identityServer/Users`, { searchText: searchText, count: count });
};

const identityServerCheckUserName = async (searchText) => {
  return getRequest(`${END_POINT}/identityServer/username`, { searchText: searchText });
};

const identityServerUserAdd = async (userName, newUser) => {
  const user = Object.assign({ userName: userName, fullName: newUser.name }, newUser);
  return postRequest(`${END_POINT}/identityServer/user`, { ...user });
};

const identityServerSyncUserRoles = async (userId) => {
  return patchRequest(`${END_POINT}/user/${userId}/syncIdentityServerRoles`);
};

const upsertUserLogins = async (userId, data) => {
  return postRequest(`${END_POINT}/user/${userId}/logins`, { items: [...data] });
};

export {
  getUsers,
  getUsersFilterOptions,
  getUser,
  getUserLogins,
  getUserName,
  getUserLoginLogs,
  getUserLoginLogsFilterOptions,
  getUserLoginLogsSummary,
  addUser,
  patchUser,
  getUserGroups,
  getUserGroupsFilterOptions,
  getUserGroup,
  getUserGroupName,
  addUserGroup,
  patchUserGroup,
  getResources,
  getResourcesFilterOptions,
  getResource,
  getResourceName,
  addResource,
  patchResource,
  getProjects,
  getProjectsFilterOptions,
  getProject,
  getProjectName,
  addProject,
  patchProject,
  getProjectLinks,
  getProjectLinksFilterOptions,
  getProjectLink,
  getProjectLinkName,
  addProjectLink,
  patchProjectLink,
  getProjectLinkSources,
  getRoles,
  getRolesFilterOptions,
  getRole,
  getRoleName,
  addRole,
  patchRole,
  getPermissions,
  getPermissionsFilterOptions,
  getPermission,
  getPermissionName,
  addPermission,
  patchPermission,
  getPermissionCategories,
  getPermissionCategoriesFilterOptions,
  getPermissionCategory,
  getPermissionCategoryName,
  addPermissionCategory,
  patchPermissionCategory,
  getAccessOverview,
  addRelationship,
  removeRelationship,
  removeUserGroupFromUser,
  removePermissionFromRole,
  removePermissionFromCategory,
  removeCategoryFromResource,
  severityCode,
  iamType,
  identityServerUsers,
  identityServerCheckUserName,
  identityServerUserAdd,
  identityServerSyncUserRoles,
  upsertUserLogins,
};
